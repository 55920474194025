import React from "react";
import loadable from "@loadable/component";
import Container from "../../../common/UI/Container";
import "./index.scss";

const Row = loadable(() => import("./Row"));

const ProInstall = () => {
  return (
    <section className="PgDMain-PI">
      <Container mode="standard">
        <h2 className="PgDMain-PI__title">
          AMZScout PRO AI-Erweiterung mit 3 Klicks installieren!
        </h2>
        <p className="PgDMain-PI__subtitle">
          Erhalten Sie in Echtzeit detaillierte Analysen zu Produkten von Amazon
        </p>
        <Row />
      </Container>
    </section>
  );
};

export default ProInstall;
